var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.mode === "create" ? _vm.$t("lbl_create") : _vm.$t("lbl_edit")
      }
    },
    [
      _c(
        "a-tabs",
        {
          staticClass: "hide-border",
          attrs: {
            "default-active-key": _vm.activeTabPane,
            "active-key": _vm.activeTabPane,
            type: "card"
          },
          on: { change: _vm.handleChangeMenu }
        },
        _vm._l(_vm.columnTabPane, function(item) {
          return _c(
            "a-tab-pane",
            { key: item, attrs: { disabled: _vm.disabledTabPane } },
            [
              _c(
                "span",
                { attrs: { slot: "tab" }, slot: "tab" },
                [
                  _vm._v(" " + _vm._s(item) + " "),
                  _c("a-icon", {
                    staticStyle: { color: "red" },
                    attrs: { type: "tag" }
                  })
                ],
                1
              ),
              _c(
                "a-form",
                _vm._b(
                  {
                    staticStyle: { paddingLeft: "1rem", paddingRight: "1rem" },
                    attrs: { layout: "vertical", form: _vm.form },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.handleSave.apply(null, arguments)
                      }
                    }
                  },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "div",
                    {
                      style:
                        _vm.activeTabPane === "General Details"
                          ? "display:block"
                          : "display:none"
                    },
                    [
                      _c("GeneralDetails", {
                        attrs: {
                          "handle-type-general-detail":
                            _vm.handleTypeGeneralDetail
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTabPane === "Account Details",
                          expression: "activeTabPane === 'Account Details'"
                        }
                      ]
                    },
                    [
                      _c("AccountDetails", {
                        attrs: { "data-top": _vm.dataTop },
                        on: { handleSearchTop: _vm.handleSearchTop }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      style:
                        _vm.activeTabPane === "Employee Details"
                          ? "display:block"
                          : "display:none"
                    },
                    [
                      _c("EmployeeDetails", {
                        attrs: {
                          "employee-number": _vm.employeeNumber,
                          "data-department": _vm.dataDepartment
                        },
                        on: {
                          handleSearchDepartment: _vm.handleSearchDepartment
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      style:
                        _vm.activeTabPane === "Customer Details"
                          ? "display:block"
                          : "display:none"
                    },
                    [
                      _c("CustomerDetails", {
                        attrs: {
                          "customer-number": _vm.customerNumber,
                          "data-customer-type": _vm.dataCustomerType
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      style:
                        _vm.activeTabPane === "Supplier Details"
                          ? "display:block"
                          : "display:none"
                    },
                    [
                      _c("SupplierDetails", {
                        attrs: {
                          "data-supplier-type": _vm.dataSupplierType,
                          "supplier-number": _vm.supplierNumber
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  style:
                    _vm.activeTabPane === "Address Details"
                      ? "display:block"
                      : "display:none"
                },
                [
                  _c("AddressDetails", {
                    attrs: { "data-tax-type": _vm.dataTaxType }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  style:
                    _vm.activeTabPane === "Bank Details"
                      ? "display:block"
                      : "display:none"
                },
                [
                  _c("BankDetails", {
                    attrs: { "active-tab-pane": _vm.activeTabPane }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "a-row",
        { staticStyle: { marginTop: "1rem" }, attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 12 } },
            [
              _c("span", { staticStyle: { "margin-right": "1rem" } }, [
                _vm._v(" Active ? ")
              ]),
              _c("a-switch", {
                model: {
                  value: _vm.active,
                  callback: function($$v) {
                    _vm.active = $$v
                  },
                  expression: "active"
                }
              })
            ],
            1
          ),
          _vm.activeTabPane !== _vm.columnTabPane[_vm.columnTabPane.length - 1]
            ? _c(
                "a-col",
                {
                  staticStyle: { display: "flex", justifyContent: "flex-end" },
                  attrs: { span: 12, xs: 24, sm: 24, md: 12 }
                },
                [
                  _c(
                    "a-space",
                    [
                      _vm.activeTabPane !== _vm.columnTabPane[0]
                        ? _c(
                            "a-button",
                            {
                              staticStyle: {
                                background: "#FFD966",
                                color: "black",
                                border: "#FFD966"
                              },
                              attrs: { icon: "left" },
                              on: { click: _vm.handleBack }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_previous")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          staticStyle: {
                            background: "#FFD966",
                            color: "black",
                            border: "#FFD966"
                          },
                          attrs: { icon: "right" },
                          on: { click: _vm.handleNext }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_next")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.activeTabPane === _vm.columnTabPane[_vm.columnTabPane.length - 1]
            ? _c(
                "a-col",
                { attrs: { xs: 24, sm: 24, md: 12, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: {
                            background: "#FFD966",
                            color: "black",
                            border: "#FFD966"
                          },
                          attrs: { icon: "left" },
                          on: { click: _vm.handleBack }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_previous")) + " ")]
                      ),
                      _c("a-button", { on: { click: _vm.handleCancel } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loadingSave,
                            icon: "save"
                          },
                          on: { click: _vm.handleSave }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }