

















































































































































import { Messages } from "@/models/enums/messages.enum";
import Vue from "vue";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { contactServices } from "@service/contact.service";
import { masterServices } from "@/services/master.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { settingsServices } from "@/services/settings.service";
import { ResponseListOfCoa, ResponsePreference } from "@/models/interface/settings.interface";
import { Mode } from "@/models/enums/global.enum";
import { Mutations } from "@/models/enums/mutations.enum";
import { debounceProcess } from "@/helpers/debounce";
import moment, { Moment } from "moment";
import { PropsModel } from "@/models/interface/common.interface";
import { IBankData, RequestContactDataCreate, ResponseContactData } from "@/models/interface-v2/contact.interface";
import { EmployeeData } from "@/models/interface-v2/employee.interface";
import { SupplierData } from "@/models/interface-v2/supplier.interface";
import { IOption } from "@/models/interface-v2/common.interface";
import { masterTypeService } from "@/services-v2/master-type.service";
import { mapGetters, mapState } from "vuex";
import { PREFERENCE_KEY } from "@/models/constant/preference.constant";

interface InterFaceBankDataList extends IBankData {
  key:number
}

interface InterfaceDataEmployee {
  gender: string | null,
  dateOfBirth: Moment | string | null,
  startDate: Moment | string | null,
  position: string | null,
  nik: string | null
}

interface InterfaceDataCustomer {
  customerType: string | null,
  creditLimit: number | null,
  discount: number | null,
  taxId: string;
}

export default Vue.extend({
  name: "CreateContact",
  data () {
    this.handleSearchTop = debounceProcess(this.handleSearchTop, 400);
    return {
      disabledTabPane: true as boolean,
      page: 0 as number,
      limit: 10 as number,
      idContact: "" as string,
      mode: "Create" as string,
      active: true as boolean,
      activeTabPane: "General Details" as string,
      dataContact: {} as ResponseContactData,
      employeeNumber: "" as string | null,
      customerNumber: "" as string | null,
      supplierNumber: "" as string | null,
      dataDepartment: [] as ResponseListMaster[],
      columnTabPane: [
        "General Details",
        "Address Details",
        "Bank Details",
        "Account Details",
        // 'Employee Details',
        // 'Customer Details'
      ] as string[],
      loadingSave: false as boolean,
      dataTaxType: [] as ResponseListMaster[],
      dataTop: [] as IOption[],
      dataSupplierType: [] as ResponseListMaster[],
      dataCustomerType: [] as ResponseListMaster[],
      dataCoa: {} as ResponseListOfCoa,
      form: this.$form.createForm(this, { name: "contactData" }),
    };
  },
  computed: {
    ...mapState({
      storePreference: (store: any) => store.preferenceStore.appPreference as ResponsePreference[]
    }),
    ...mapGetters({
      getPreferenceValue: "preferenceStore/GET_PREFERENCE_VALUE",
    }),
    prefAccAr(): string {
      return this.getPreferenceValue(PREFERENCE_KEY.ACC_SETUP_ACCOUNT_RECEIVABLES)?.value || "";
    },
    prefAccSalesRevenue(): string {
      return this.getPreferenceValue(PREFERENCE_KEY.ACC_SETUP_SALES_REVENUE)?.value || "";
    },
    prefAccPayables(): string {
      return this.getPreferenceValue(PREFERENCE_KEY.ACC_SETUP_ACCOUNT_PAYABLES)?.value || "";
    },
    prefAccPrepayment(): string {
      return this.getPreferenceValue(PREFERENCE_KEY.ACC_SETUP_ACCOUNT_PREPAYMENT_AR)?.value || "";
    },
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
    isModeCreate(): boolean {
      return this.mode === Mode.CREATE;
    },
  },
  watch: {
    "storePreference.length": {
      immediate: false,
      handler: "initValueAccount"
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "contactData" });
  },
  created () {
    this.initForm();
    // set mode update / create
    this.mode = this.$route.meta.mode;
    this.fetchOptions();
    this.handleSearchTop("");
    this.handleSearchDepartment("");
  },
  mounted () {
    this.idContact = this.$route.params.id;
    if (this.idContact) {
      this.getDetailContact(this.idContact);
    }
    this.initValueAccount();
  },
  destroyed () {
    this.$store.commit(`contactStore/${Mutations.SET_LIST_BILL_TO}`, []);
    this.$store.commit(`contactStore/${Mutations.SET_LIST_SHIP_TO}`, []);
    this.$store.commit(`contactStore/${Mutations.SET_DATA_EMPLOYEE}`, {
      gender: "Male",
      dateOfBirth: null,
      startDate: null,
    });
    this.$store.commit(`contactStore/${Mutations.SET_BANK_DATA_LIST}`, [
      {
        bankName: null,
        bankBranch: null,
        bankAccName: null,
        bankAccNumber: null,
        key: 0
      }
    ]);
    this.$store.commit(`contactStore/${Mutations.SET_VALUE_TYPE}`, []);
    this.$store.commit(`contactStore/${Mutations.SET_ADDRESS_DATA_LIST}`, []);
  },
  methods: {
    initForm(): void {
      const fields = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        mobileNumber: "",
        faxNumber: "",
        taxRegistrationNumber: "",
        taxRegistrationName: "",
        receivablesAccount: undefined,
        revenueAccount: undefined,
        payablesAccount: undefined,
        prepaymentAccount: undefined,
        termOfPayment: "",
        active: "",
        customerType: "",
        creditLimit: "",
        discount: "",
        nik: "",
        role: "",
        supplierType: "",
        dateOfBirth: "",
        startingDate: "",
        taxInvoiceCode: "",
        supplierCode: "",
      };
      for (const key in fields) {
        this.form.getFieldDecorator(key, {
          initialValue: fields[key]
        });
      }
      this.form.setFieldsValue(fields);
    },
    getPreferenceAcc(): {
      receivablesAccount: string,
      revenueAccount: string,
      payablesAccount: string,
      prepaymentAccount: string,
    } | null {
      const obj = {
        receivablesAccount: this.prefAccAr,
        revenueAccount: this.prefAccSalesRevenue,
        payablesAccount: this.prefAccPayables,
        prepaymentAccount: this.prefAccPrepayment,
      };
      // check if any of obj prop has value
      // to trigger watcher
      return obj.receivablesAccount ? obj : null;
    },
    handleSearchDepartment (valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        name: "DEPARTMENT"
      } as RequestQueryParamsModel;

      if(valueSearch) params.search = `value~*${valueSearch}*`;
      
      this.loadingStatus = true;
      masterServices
        .listMaster(params)
          .then((data) => {
            this.dataDepartment = data;
          })
          .finally(() => this.loadingStatus = false);
    },
    handleCancel () {
      this.$router.push("/contact/read");
    },
    initValueAccount(): void {
      if (!this.isModeCreate) return;
      const acc = this.getPreferenceAcc();

      if (!acc) return;
      const values = {
        receivablesAccount: acc.receivablesAccount,
        revenueAccount: acc.revenueAccount,
        payablesAccount: acc.payablesAccount,
        prepaymentAccount: acc.prepaymentAccount,
      };
      for (const key in values) {
        this.form.getFieldDecorator(key, {
          initialValue: values[key],
        });
      }
      this.form.setFieldsValue(values);
    },
    fetchOptions(): void {
      // params
      let paramsMasterTaxType = {
        name: "TAX_TYPE"
      } as RequestQueryParamsModel;
      let paramsSupplierType = {
        name: "SUPPLIER_TYPE"
      } as RequestQueryParamsModel;
      let paramsCustomerType = {
        name: "CUSTOMER_TYPE"
      } as RequestQueryParamsModel;

      // tax type
      masterServices
        .listMaster(paramsMasterTaxType)
          .then((data) => {
            this.dataTaxType = data;
          });

      // supplier type
      masterServices
        .listMaster(paramsSupplierType)
          .then((data) => {
            this.dataSupplierType = data;
          });

      // customer type
      masterServices
        .listMaster(paramsCustomerType)
          .then((data) => {
            this.dataCustomerType = data;
          });
    },
    getDetailContact(id: string): void {
      this.disabledTabPane = false;
      contactServices.getContactData(id)
        .then((data) => {
          this.active = data.active;
          let paramsListOfCoa = {
            page: 0,
            limit: 10,
          } as RequestQueryParamsModel;
          const promise1 = settingsServices.listOfCoa(paramsListOfCoa, `/${data.receivableAccount.id}`);
          const promise2 = settingsServices.listOfCoa(paramsListOfCoa, `/${data.revenueAccount.id}`);
          const promise3 = settingsServices.listOfCoa(paramsListOfCoa, `/${data.payableAccount.id}`);
          const promise4 = settingsServices.listOfCoa(paramsListOfCoa, `/${data.prepaymentAccount.id}`);
                
          Promise.all([promise1, promise2, promise3, promise4])
            .then((value:PropsModel) => {
              let temp = {
                data: value,
                currentPage: 0,
                totalPages: 0,
                totalElements: 1,
              } as ResponseListOfCoa;
              this.dataCoa = temp;
            });
          this.dataContact = data;
          const listBillTo = this.getDataShipToOrBillTo("Bill To", data.addressDataList);
          const listShipTo = this.getDataShipToOrBillTo("Ship To", data.addressDataList);
          const dataEmployee = this.getDataEmployee(data.employeeData);
          const dataCustomer = this.getDataCustomer(data.customerData);
          const dataSupplier = this.getDataSupplier(data.supplierData);
          const columnTabPane = [] as string[];
          if(data.supplier) {
            columnTabPane.push("Supplier Details");
            this.columnTabPane.push("Supplier Details");
          }
          if(data.employee) {
            columnTabPane.push("Employee Details");
            this.columnTabPane.push("Employee Details");
            this.handleSearchDepartment(dataEmployee.position);
          }
          if(data.customer) {
            columnTabPane.push("Customer Details");
            this.columnTabPane.push("Customer Details");
          }
          this.employeeNumber = data.employeeNumber;
          this.supplierNumber = data.supplierNumber;
          this.customerNumber = data.customerNumber;
          // data.bankDataList.map((data.index) => data.key = index)
          const newBankList = this.getDataBankList(data.bankDataList);
          this.$store.commit(`contactStore/${Mutations.SET_LIST_BILL_TO}`, listBillTo);
          this.$store.commit(`contactStore/${Mutations.SET_LIST_SHIP_TO}`, listShipTo);
          this.$store.commit(`contactStore/${Mutations.SET_DATA_EMPLOYEE}`, dataEmployee);
          this.$store.commit(`contactStore/${Mutations.SET_BANK_DATA_LIST}`, newBankList);
          this.$store.commit(`contactStore/${Mutations.SET_VALUE_TYPE}`, columnTabPane);
          this.form.setFieldsValue({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: this.checkDataStringOrNull(data.phoneNumber),
            mobileNumber: this.checkDataStringOrNull(data.mobileNumber),
            faxNumber: this.checkDataStringOrNull(data.fax),
            taxRegistrationNumber: data.taxRegisNumber,
            taxRegistrationName: data.taxRegisName,
            receivablesAccount: data.receivableAccount.id,
            revenueAccount: data.revenueAccount.id,
            payablesAccount: data.payableAccount.id, // code - description yang tampil
            prepaymentAccount: data.prepaymentAccount.id,
            termOfPayment: data.top?.toString() ?? "",
            active: data.active,
            customerType: dataCustomer.customerType,
            creditLimit: dataCustomer.creditLimit,
            discount: dataCustomer.discount,
            nik: dataEmployee.nik,
            role: dataEmployee.position,
            supplierType: dataSupplier.supplierType,
            dateOfBirth: moment(dataEmployee.dateOfBirth, "DD MM yyyy"),
            startingDate: moment(dataEmployee.startDate, "DD MM yyyy"),
            taxInvoiceCode: dataCustomer.taxId,
            supplierCode: data.supplierCode,
          });
        });
    },
    handleSearchTop (value) {
      let paramsTop = {
      name: "TOP"
    } as RequestQueryParamsModel;
    if(value) paramsTop.search = `value~*${value}*`;
    masterTypeService
      .listMaster(paramsTop)
        .then((data) => {
          this.dataTop = data.map(x => {
            return { key: this.getTOPLabel(x.value), value: x.value };
          });
        });
    },
    getTOPLabel(top: string): string {
      if (top === "0") {
        return "lbl_cash_on_delivery";
      } else if (top === "-1") {
        return "lbl_cash_on_advance";
      } else {
        return top;
      }
    },
    checkColumnTabPane (err):string[] {
      let typesGeneralDetails:string[] = [];
      if(!this.columnTabPane.includes("Customer Details")) {
        typesGeneralDetails.push("Customer Details");
        delete err["customerType"];
      }
      if(!this.columnTabPane.includes("Supplier Details")) {
        typesGeneralDetails.push("Supplier Details");
        delete err["supplierType"];
        delete err["supplierCode"];
      }
      if(!this.columnTabPane.includes("Employee Details")) {
        typesGeneralDetails.push("Employee Details");
        delete err["nik"];
        delete err["role"];
        delete err["dateOfBirth"];
        delete err["startingDate"];
      }
      return typesGeneralDetails;
    },
    handleMandatoryField (err): string[] {
      let tempDescription = [] as string[];
      if(err && Object.keys(err).length > 0) {
        Object.keys(err).forEach(key => tempDescription.push(`Form ${key},`));
        tempDescription.push("is Mandatory");
      }
      return tempDescription;
    },
    checkDataStringOrNull (value): null | string {
      if(value) return value;
      else return null;
    },
    checkDataNumberOrNull (value): null | number {
      if(value || value === 0) return value;
      else return null;
    },
    checkSupplierCustomerEmployee (): {supplier: boolean, customer: boolean, employee: boolean} {
      const supplier = this.columnTabPane.includes("Supplier Details") ? true : false;
      const customer = this.columnTabPane.includes("Customer Details") ? true : false;
      const employee = this.columnTabPane.includes("Employee Details") ? true : false;
      return {
        supplier,
        customer,
        employee
      };
    },
    checkCustomerData(isCustomer, res): { customerType: string | null, creditLimit: number | null, discount: number | null, taxId: string } | null {
      if(isCustomer) {
        return {
          customerType: this.checkDataStringOrNull(res.customerType),
          creditLimit: this.checkDataNumberOrNull(res.creditLimit),
          discount: this.checkDataNumberOrNull(res.discount),
          taxId: res.taxInvoiceCode,
        };
      }
      else if (this.dataContact.customerData) return this.dataContact.customerData;
      else return null;
    },
    checkEmployeeData(isEmployee, res): EmployeeData | null {
      if(isEmployee) {
        return {
          nik: res.nik,
          gender: this.$store.state.contactStore.dataEmployee.gender, // radio button,
          position: res.role,
          dateOfBirth: this.$store.state.contactStore.dataEmployee.dateOfBirth, // date,
          startDate: this.$store.state.contactStore.dataEmployee.startDate // date,
        };
      } 
      else if (this.dataContact.employeeData)return this.dataContact.employeeData;
      else return null;
    },
    checkSupplierData(isSupplier, res): SupplierData | null {
      if(isSupplier) return {
        supplierType: this.checkDataStringOrNull(res.supplierType),
      };
      else if(this.dataContact.supplierData) return this.dataContact.supplierData;
      else return null;
    },
    handleSave () {
      let tempDescription = [] as string[];
      let typesGeneralDetails = [] as string[];
      this.form.validateFields((err,res) => {
        // checker tab
        typesGeneralDetails = this.checkColumnTabPane(err);


        // Description Notif Field" Mandatory
        tempDescription = this.handleMandatoryField(err);

        // checker Address Data List
        if(this.$store.state.contactStore.listBillTo.concat(this.$store.state.contactStore.listShipTo).length === 0) {
          this.handleNotifError("Tab Address Details", "ShipTo or BillTo at least have 1 data");
        }

          let checkerBankDataRequired = false;
          const checkBank = this.handleBankDetails();
          // payloadBankDataList = checkBank.dataBank
          checkerBankDataRequired = checkBank.checkerBankDataRequired;

          // checker bank list min 1
          // if(payloadBankDataList.length < 1) {
          //   this.handleNotifError('Tab Bank Details', 'Bank Details at least have 1 data')
          // }

          // checker bank data required
          if(checkerBankDataRequired) {
            this.handleNotifError("Tab Bank Details", "Form bankName, Form bankBranchName, Form bankAccountName, Form bankAccountNumber is Mandatory");
          }

          // checker type jika tidak diisi
          if(typesGeneralDetails.length === 3) {
            this.handleNotifError("Tab General Details", "Choose at least one type (Customer, Supplier or Employee) !");
          }

          // ===========
        
        // stop if error
        if(
          tempDescription.length > 0 ||
          this.$store.state.contactStore.listBillTo.concat(this.$store.state.contactStore.listShipTo).length < 1 ||
          // payloadBankDataList.length < 1 || // karena skrg bisa kosoong
          checkerBankDataRequired ||
          typesGeneralDetails.length === 3
        ) return;
        

        // create or update begini

        // assign payload
        // role = supplier | customer | employee
        const isRole = this.checkSupplierCustomerEmployee();
        const customerData = this.checkCustomerData(isRole.customer, res);
        const employeeData = this.checkEmployeeData(isRole.employee, res);
        const supplierData = this.checkSupplierData(isRole.supplier, res);
        const payload: RequestContactDataCreate = {
          supplier: isRole.supplier,
          customer: isRole.customer,
          employee: isRole.employee,
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          phoneNumber: this.checkDataStringOrNull(res.phoneNumber),
          mobileNumber: this.checkDataStringOrNull(res.mobileNumber),
          fax: this.checkDataStringOrNull(res.faxNumber),
          taxRegisNumber: res.taxRegistrationNumber,
          taxRegisName: res.taxRegistrationName,

          receivableAccountId: res.receivablesAccount,
          revenueAccountId: res.revenueAccount,
          payableAccountId: res.payablesAccount,
          prepaymentAccountId: res.prepaymentAccount,
          top: this.checkDataNumberOrNull(res.termOfPayment),

          active: this.active,
          addressDataList: this.$store.state.contactStore.listBillTo.concat(this.$store.state.contactStore.listShipTo),
          bankDataList: this.$store.state.contactStore.bankDataList,
          customerData: customerData,
          employeeData: employeeData,
          supplierData: supplierData,
          customerNumber: "",
          employeeNumber: "",
          supplierNumber: "",
          supplierCode: res.supplierCode,
        };

        switch(this.mode) {
          case Mode.EDIT:
            this.loadingSave = true;
            contactServices.updateContactData(payload, this.idContact)
            .then(()=> {
              this.$message.success(Messages.UPDATE_SUCCESS);
              this.$router.push("/contact/read");
            })
            .catch(() => this.$message.error(Messages.UPDATE_FAIL))
            .finally(() => this.loadingSave = false);
            break;
          case Mode.CREATE:
            this.loadingSave = true;
            contactServices.createContactData(payload)
            .then(()=> {
              this.$message.success(Messages.CREATE_SUCCESS);
              this.$router.push("/contact/read");
            })
            .catch(() => this.$message.error(Messages.CREATE_FAIL))
            .finally(() => this.loadingSave = false);
            break;
          default:
            break;
        }
      });

      if(tempDescription.length > 0) {
        this.handleNotifError("Error !", `${tempDescription.join(" ")}`);
      }
    },
    handleCheckGeneralDetails (err):boolean {
      let youCanPass = false;
      if(err && Object.keys(err).length > 0) {
        for (const key in err) {
          if(key === "firstName" || key === "lastName" || key === "email" || key === "taxRegistrationNumber" || key === "taxRegistrationName") {
            return false;
            // checker tab
          } else if (!this.columnTabPane.includes("Customer Details") && !this.columnTabPane.includes("Supplier Details") && !this.columnTabPane.includes("Employee Details")) {
            this.$notification.error({
              message: "Error",
              description: "Choose at least 1 type (Customer, Supplier or Employee) !"
            });
            return false;
          } else {
            youCanPass = true;
          }
        }
      } else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleCheckAccountDetails (err): boolean {
      let youCanPass = false;
      if(err && Object.keys(err).length > 0) {
        for (const key in err) {
          if(key === "receivablesAccount" || key === "revenueAccount" || key === "payablesAccount" || key === "prepaymentAccount") {
            return false;
          } else {
            youCanPass = true;
          }
        }
      } else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleCheckEmployeeDetails (err): boolean {
      let youCanPass = false;
      if(err && Object.keys(err).length > 0) {
        for (const key in err) {
          if(key === "nik" || key === "role") {
            return false;
          } else {
            youCanPass = true;
          }
        }
      } else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleCustomerDetails (err): boolean {
      let youCanPass = false;
      if(err && Object.keys(err).length > 0) {
        for (const key in err) {
          if(key === "customerType") {
            return false;
          } else {
            youCanPass = true;
          }
        }
      } else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleSupplierDetails (err): boolean {
      let youCanPass = false;
      if(err && Object.keys(err).length > 0) {
        for (const key in err) {
          if(key === "supplierType") {
            return false;
          } else {
            youCanPass = true;
          }
        }
      } else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleAddressDetails (): boolean {
      let youCanPass = false;
      const checkAddress = this.$store.state.contactStore.listBillTo.concat(this.$store.state.contactStore.listShipTo);
      if(checkAddress.length === 0) {
        setTimeout(() => {
          this.$notification.error({
            message: "Tab Address Details",
            description: "ShipTo or BillTo at least have 1 data"
          });
        }, 500);
        return false;
      }else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleBankDetails ():PropsModel {
      let checkerBankDataRequired = false;
      const dataBank = this.$store.state.contactStore.bankDataList.filter(data => {
        if(!data.bankName) {
          return;
          // check required data bank
        } else if (data.bankName) {

          if(!data.bankBranch) checkerBankDataRequired = true;
          if(!data.bankAccName) checkerBankDataRequired = true;
          if(!data.bankAccNumber) checkerBankDataRequired = true;

        }
        return {
          bankName: data.bankName,
          bankBranch: data.bankBranch,
          bankAccName: data.bankAccName,
          bankAccNumber: data.bankAccNumber
        };
      });
      return {
        dataBank,
        checkerBankDataRequired
      };
    },
    handleNotifError (message, description) {
      this.$notification.error({
        message,
        description
      });
    },
    handleNext () {
      const index = this.columnTabPane.indexOf(this.activeTabPane);
      const tabNow = this.columnTabPane[index];
      let checkerBankDataRequired = false;
      let payloadBankDataList = [];
      let checkBank;
      // this.activeTabPane = this.columnTabPane[index + 1]
      this.form.validateFields((err: Error[]) => {
        let youCanPass = false;
        // checker tab pane
        this.checkColumnTabPane(err);
        switch(tabNow) {
          case "General Details":
            youCanPass = this.handleCheckGeneralDetails(err);
            break;
          case "Account Details":
            youCanPass = this.handleCheckAccountDetails(err);
            break;
          case "Employee Details":
            youCanPass = this.handleCheckEmployeeDetails(err);
            break;
          case "Customer Details":
            youCanPass = this.handleCustomerDetails(err);
            break;
          case "Supplier Details":
            youCanPass = this.handleSupplierDetails(err);
            break;
          case "Address Details":
            youCanPass = this.handleAddressDetails();
            break;
          case "Bank Details":
            checkBank = this.handleBankDetails();
            payloadBankDataList = checkBank.dataBank;
            checkerBankDataRequired = checkBank.checkerBankDataRequired;
            // checker bank list min 1
            // if(payloadBankDataList.length < 1) {
            //   this.handleNotifError('Tab Bank Details', 'Bank Details at least have 1 data')
            // }
            // checker bank data required
            if(checkerBankDataRequired) {
              this.handleNotifError("Tab Bank Details", "Form bankName, Form bankBranchName, Form bankAccountName, Form bankAccountNumber is Mandatory");
            }
            /* eslint-disable */
            let uniqueName = [...new Set(payloadBankDataList.map(a => a['bankName']))];
            let uniqueValue = [...new Set(payloadBankDataList.map(a => a['bankAccNumber']))];
            const isDuplicate = (payloadBankDataList.length != uniqueName.length && payloadBankDataList.length != uniqueValue.length)
            if(payloadBankDataList.length > 0 && !checkerBankDataRequired && !isDuplicate) {
              youCanPass = true
            } else if (payloadBankDataList.length === 0){
              youCanPass = true
            }
            else{
              this.handleNotifError("Error", "Cannot duplicate Bank Name and Bank Bank Account Number")
            }
            break;
        }
        if (!youCanPass) return;
        this.activeTabPane = this.columnTabPane[index + 1]
      })
    },
    handleBack () {
      const index = this.columnTabPane.indexOf(this.activeTabPane)
      this.activeTabPane = this.columnTabPane[index - 1]
    },
    handleChangeMenu (value) {
      this.activeTabPane = value
    },
    handleTypeGeneralDetail (value) {
      this.$store.commit(`contactStore/${Mutations.SET_VALUE_TYPE}`, value)
      this.columnTabPane = this.columnTabPane.filter(nameTabpane => {
        if(nameTabpane === 'Employee Details' || nameTabpane === 'Customer Details' || nameTabpane === 'Supplier Details') {
          if(!value.includes(nameTabpane)) {
            return;
          } else {
            return nameTabpane
          }
        } else {
          return nameTabpane
        }
      })
      value.map(dataType => {
        if(this.columnTabPane.includes(dataType)) {
          return;
        } else {
          this.columnTabPane.push(dataType)
        }
      })
    },
    getDataShipToOrBillTo (shipToOrBillTo, addressDataList) {
      if(shipToOrBillTo === 'Ship To') return addressDataList.filter(dataFilter => dataFilter.shipTo)
      else return addressDataList.filter(dataFilter => dataFilter.billTo)
    },
    getDataEmployee (dataEmployee): InterfaceDataEmployee {
      if(dataEmployee) {
        return {
          gender: this.checkDataStringOrNull(dataEmployee.gender),
          dateOfBirth: dataEmployee.dateOfBirth ? dataEmployee.dateOfBirth : null,
          startDate: dataEmployee.startDate ? dataEmployee.startDate : null,
          position: this.checkDataStringOrNull(dataEmployee.position),
          nik: this.checkDataStringOrNull(dataEmployee.nik)
        }
      } else {
        return {
          gender: null,
          dateOfBirth: null,
          startDate: null,
          position: null,
          nik: null
        }
      }
    },
    getDataCustomer (dataCustomer): InterfaceDataCustomer {
      if(dataCustomer) {
        return {
          customerType: this.checkDataStringOrNull(dataCustomer.customerType),
          creditLimit: this.checkDataNumberOrNull(dataCustomer.creditLimit),
          discount: this.checkDataNumberOrNull(dataCustomer.discount),
          taxId: dataCustomer.taxId,
        }
      } else {
        return {
          customerType: null,
          creditLimit: null,
          discount: null,
          taxId: "",
        }
      }
    },
    getDataSupplier(dataSupplier): { supplierType: string | null } {
      if(dataSupplier) return {
        supplierType: this.checkDataStringOrNull(dataSupplier.supplierType),
      }
      else return {
        supplierType: null,
      }
    },
    getDataBankList (bankDataList: InterFaceBankDataList[]): InterFaceBankDataList[] {
      return bankDataList.map((dataMap,index) => {dataMap.key = index; return dataMap})
    },
  },
})
